var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"300px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"绑定邮箱"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入联系邮箱' },
              { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' } ]
          }]),expression:"['email', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入联系邮箱' },\n              { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },\n            ]\n          }]"}],attrs:{"placeholder":"请输入邮箱地址"}})],1),_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":13}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email_captcha', {
                normalize: this.$lodash.trim,
                rules: [{ required: true, message: '请输入验证码' }]
              }]),expression:"['email_captcha', {\n                normalize: this.$lodash.trim,\n                rules: [{ required: true, message: '请输入验证码' }]\n              }]"}],attrs:{"placeholder":"请输入验证码"}})],1),_c('a-col',{attrs:{"span":11}},[_c('a-button',{staticClass:"simiot-register-form-captcha-button",attrs:{"type":"primary","block":true,"disabled":_vm.disabled},on:{"click":_vm.getEmailCaptcha}},[_vm._v(" "+_vm._s(_vm.btnTitle)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }